<script setup>
import { /*inject,*/ reactive } from 'vue'
import { useQuotationStore } from './../stores/quotation'
import { createQuote } from './../services/woo'
import Loading from './../components/Loading.vue'

const quotationStore = useQuotationStore();
//const { formatPrice } = inject('formatPrice')

quotationStore.$totalPrice();

const state = reactive({
    step: 'not sent',
    loading: {
        message: 'Envoi de votre devis, merci de patienter',
    },
});

const finish = () => {
    state.step = 'sending';

    let productsQuote = quotationStore._essenceId + ',' + quotationStore._structureId + ',' + quotationStore._fixationId + ',';
    (quotationStore._accessoires).forEach((v, k) => {
    productsQuote += k + ',';
    });
    if (quotationStore._accessoires.length == 0) {
    productsQuote += '-1,';
    }

    const quoteJson = {
        'action': 'createQuote',
        'id' : quotationStore._customer['id'],
        'surface': quotationStore._surface,
        'type': quotationStore._type,
        'products' : productsQuote,
        'poseur' : quotationStore._poseur,
        'delai' : quotationStore._delai,
        'rappel' : quotationStore._rappel,
        'message' : quotationStore._message
      };
    createQuote(quoteJson).then((res) => {
       // console.log('res status = ', res);
        if (res.status == 1) {
            state.step = 'sent';
            state.loading.status = false;
        } else if (res.status == 0) {
            state.step = 'error';
            state.loading.status = false;
        } else {
            state.step = 'error';
            state.loading.status = false;
        }
    });
}
</script>

<template>
    <div v-if="state.step == 'sending'" class="page-content">
        <Loading v-bind="{'msg': state.loading.message, 'status': true}" />
    </div>
    <div  v-if="state.step == 'not sent'" class="page-content">
        <div class="page-title">
            <h1>Récapitulatif de votre devis</h1>
        </div>
        <div class="page-quotation-confirmation">
            <div class="page__quotation__confirmation-page__quotation__subtitle">
                Essence de bois
            </div>
            <div class="item-container page__quotation__confirmation-page__quotation__product">
                <div class="col-3">
                    <img :src="quotationStore._essenceImg" alt="Essence de bois" />
                </div>
                <div class="col-6 product-content-main">
                    <strong>{{ quotationStore._essenceName }}</strong>
                    <div class="page__quotation__confirmation-page__quotation__product__description" v-html="quotationStore._essenceDescription"></div>
                </div>
                <div class="col-3 product-content-right">
                    <!-- <p> <b>Prix TTC :</b> {{ formatPrice(quotationStore._essencePrice) }} /m2 €</p> -->
                    <p> <b>Surface :</b> {{ quotationStore._surface }} m2</p>
                </div>
            </div>

            <div class="page__quotation__confirmation-page__quotation__subtitle">
                Supports & Fixations
            </div>
            <div v-if="quotationStore._structureId" class="item-container page__quotation__confirmation-page__quotation__product">
                <div class="col-3">
                    <img :src="quotationStore._structureImg" alt="Supports & Fixations" />
                </div>
                <div class="col-6 product-content-main">
                    <strong>{{ quotationStore._structureName }}</strong>
                    <div class="page__quotation__confirmation-page__quotation__product__description" v-html="quotationStore._structureDescription"></div>
                </div>
                <div class="col-3 product-content-right">
                    <!--<p> <b>Prix TTC :</b> {{ formatPrice(quotationStore._structurePrice) }} €</p>-->
                </div>
            </div>
            <div v-if="quotationStore._structureId == 0" class="item-container page__quotation__confirmation-page__quotation__product">
                <div class="col-3">
                    <img src="./../assets/already.png" alt="J'ai déjà ma structure" />
                </div>
                <div class="col-6 product-content-main">
                    <strong>J'ai déjà ma structure</strong>
                </div>
                <div class="col-3 product-content-right">
                </div>
            </div>
            <div v-if="quotationStore._fixationId" class="item-container page__quotation__confirmation-page__quotation__product">
                <div class="col-3">
                    <img :src="quotationStore._fixationImg" alt="Supports & Fixations" />
                </div>
                <div class="col-6 product-content-main">
                    <strong>{{ quotationStore._fixationName }}</strong>
                    <div class="page__quotation__confirmation-page__quotation__product__description" v-html="quotationStore._fixationDescription"></div>
                </div>
                <div class="col-3 product-content-right">
                    <!--<p> <b>Prix TTC :</b> {{ formatPrice(quotationStore._fixationPrice) }} €</p>-->
                </div>
            </div>
            <div v-if="quotationStore._fixationId == 0" class="item-container page__quotation__confirmation-page__quotation__product">
                <div class="col-3">
                    <img src="./../assets/already.png" alt="J'ai déjà mes fixations" />
                </div>
                <div class="col-6 product-content-main">
                    <strong>J'ai déjà mes fixations</strong>
                </div>
                <div class="col-3 product-content-right">
                    
                </div>
            </div>

            <div class="page__quotation__confirmation-page__quotation__subtitle">
                Accessoires
            </div>

            <div v-for="accessoire in quotationStore._accessoires" :key="accessoire" class="accessoire-item-list">
                <div v-if="accessoire" class="item-container page__quotation__confirmation-page__quotation__product">
                    <div class="col-3">
                        <img v-if="accessoire && accessoire.img" :src="accessoire.img" alt="Accessoire" />
                    </div>
                    <div class="col-6 product-content-main">
                        <strong v-if="accessoire && accessoire.name">{{ accessoire.name }}</strong>
                        <div class="page__quotation__confirmation-page__quotation__product__description" v-html="accessoire.description"></div>
                    </div>
                    <div class="col-3 product-content-right">
                        <!--<p v-if="accessoire && accessoire.price"> <b>Prix TTC :</b> {{ formatPrice(accessoire.price) }} €</p>-->
                    </div>
                </div>
            </div>

            <!--<div class="page__quotation__confirmation-page__quotation__subtitle total">
                <span>Total de votre devis :</span>
                <span>{{ formatPrice(quotationStore._totalPrice) }} €</span>
            </div>-->
            <div class="page__quotation__confirmation-page__quotation__infos">
                Cliquez sur le bouton ci-dessous pour recevoir votre devis détaillé par email. Vous pourrez consulter le détail des produits sélectionnés et les prix associés.<br>
                Si besoin n’hésitez pas à nous contacter : <a href="https://www.deckibois.fr/contact/" target="_blank">https://www.deckibois.fr/contact/</a>
            </div>
            <div class="nav">
                <button class="mdc-button mdc-button--raised mdc-button--primary" @click="finish()">Recevoir le devis détaillé</button>
            </div>
        </div>
    </div>
    <div v-else-if="state.step == 'sent'" class="page-content">
        <div class="page-title">
            <h1>Votre devis a bien été envoyé</h1>
        </div>
        <div class="page-quotation-confirmation">
            <div class="page__quotation__confirmation-page__quotation__subtitle">
                Merci pour votre demande de devis
            </div>
            <div class="page__quotation__confirmation-page__quotation__infos">
                Votre demande de devis a bien été envoyée, vous allez recevoir un email de confirmation dans quelques minutes. <b>Attention, ce mail peut arriver dans vos courriers indésirables.</b>
                <br>
                Si besoin n’hésitez pas à nous contacter : <a href="https://www.deckibois.fr/contact/" target="_blank">https://www.deckibois.fr/contact/</a>
            </div>
        </div>
        <div class="devis-link-section">
          <div class="col" v-bind:style="{ backgroundImage: 'url(' + require('./../assets/devis.jpg') + ')' }">
            <a href="https://devis.deckibois.fr" class="devis-link-section--link">
              Nouveau devis
            </a>
          </div>
          <div class="col" v-bind:style="{ backgroundImage: 'url(' + require('./../assets/website.jpg') + ')' }">
            <a href="https://www.deckibois.fr/vente-en-ligne-lames-et-accessoires-terrasse-en-bois/" target="_blank" class="devis-link-section--link">
              Boutique en ligne
            </a>
          </div>
          <div class="col" v-bind:style="{ backgroundImage: 'url(' + require('./../assets/website.jpg') + ')' }">
            <a href="https://deckibois.com/" target="_blank" class="devis-link-section--link">
              Le blog
            </a>
          </div>
        </div>
    </div>
    <div v-else-if="state.step == 'error'" class="page-content">
        <div class="page-title">
            <h1>Une erreur est survenue</h1>
        </div>
        <div class="page-quotation-confirmation">
          <div class="page__quotation__confirmation-page__quotation__subtitle">
              Une erreur est survenue lors de l'envoi de votre devis
          </div>
          <div class="page__quotation__confirmation-page__quotation__infos">
              Une erreur est survenue lors de l'envoi de votre devis, veuillez réessayer ultérieurement.
              <br>
              Si besoin n’hésitez pas à nous contacter : <a href="https://www.deckibois.fr/contact/" target="_blank">https://www.deckibois.fr/contact/</a>
          </div>
        </div>
        <div class="devis-link-section">
          <div class="col" v-bind:style="{ backgroundImage: 'url(' + require('./../assets/devis.jpg') + ')' }">
            <a href="https://devis.deckibois.fr" class="devis-link-section--link">
              Nouveau devis
            </a>
          </div>
          <div class="col" v-bind:style="{ backgroundImage: 'url(' + require('./../assets/website.jpg') + ')' }">
            <a href="https://www.deckibois.fr/vente-en-ligne-lames-et-accessoires-terrasse-en-bois/" target="_blank" class="devis-link-section--link">
              Boutique en ligne
            </a>
          </div>
          <div class="col" v-bind:style="{ backgroundImage: 'url(' + require('./../assets/website.jpg') + ')' }">
            <a href="https://deckibois.com/" target="_blank" class="devis-link-section--link">
              Le blog
            </a>
          </div>
        </div>
    </div>
</template>

<style scoped>
.page-quotation-confirmation {
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
}

.page__quotation__confirmation-page__quotation__subtitle {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 20px;
    background-color: #f5f5f5;
    padding: 10px;
}

.item-container {
    display: flex;
    margin-top: 30px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
}

.page__quotation__subtitle {
    text-align: left;
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px;
}

.product-content-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 60px;
    text-align: left;
}

.product-content-main strong {
    font-size: 1.1em;
    font-weight: bold;
}

.product-content-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 20px;
    text-align: right;
}

.product-content-right p {
    margin: 0;
}

.col-3 {
    width: 20%;
}

.col-6 {
    width: 60%;
}

img {
    width: 100%;
}

.accessoire-item-list {
    margin-top: 10px;
}

.total {
    display: flex;
    justify-content: space-between;
}

.page__quotation__confirmation-page__quotation__infos {
    text-align: left;
    margin-top: 20px;
    padding: 10px;
}

.devis-link-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  width: 95%;
  margin: auto;
}

.devis-link-section .col {
  width: 30%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
}

.devis-link-section--link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  font-size: 1.5em;
}

.devis-link-section--link:hover {
  background-color: rgba(239, 171, 35, 0.8);
  font-size: 1.6em;
  transition: 0.3s;
}

@media (max-width: 1024px) {
  .devis-link-section .col {
    width: 100%;
    height: 200px;
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
    .page__quotation__confirmation-page__quotation__subtitle {
        font-size: 1em;
    }

    .page__quotation__confirmation-page__quotation__subtitle total {
        font-size: 1em;
    }
    
    .product-content-main {
        font-size: 0.5em;
        padding-left: 12px;
    }

    .product-content-right {
        font-size: 0.5em;
        padding: 0 10px;
    }

    .nav {
        width: 100%;
    }

    .nav button {
        width: 100%;
    }
}
</style>