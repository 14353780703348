import { useServiceStore } from './../stores/service';

export const getProducts = async(tag, wooId, wooPass)  => {
    const serviceStore = useServiceStore();
    try {
        serviceStore._inProgress = true;
        const response = await fetch('https://www.deckibois.fr/wp-json/wc/v3/products?per_page=100&order=asc&orderby=price&tag=' + tag, {
            headers: new Headers({
              "Authorization": "Basic " + btoa(wooId + ':' + wooPass)
            })
        });
        return await response.json();
    }
    catch (e) {
        return false;
    }
    finally {
        serviceStore._inProgress = false;
    }
}

export const getCustomerByMail = async(mail, wooId, wooPass)  => {
    const serviceStore = useServiceStore();
    try {
        serviceStore._inProgress = true;
        const response = await fetch('https://www.deckibois.fr/wp-json/wc/v3/customers?email=' + mail, {
            headers: new Headers({
              "Authorization": "Basic " + btoa(wooId + ':' + wooPass)
            })
        });
        return await response.json();
    }
    catch (e) {
        return false;
    }
    finally {
        serviceStore._inProgress = false;
    }
}

export const updateOrCreateCustomer = async(customer, wooId, wooPass, type)  => {
    const serviceStore = useServiceStore();
    try {
        //console.log('Customer = ',customer, wooId, wooPass, type);
        serviceStore._inProgress = true;
        if (type == "POST") {
            customer.id = '';
        } else {
           delete(customer.email);
           delete(customer.username);
        }
        const response = await fetch('https://www.deckibois.fr/wp-json/wc/v3/customers/' + customer.id, {
            method: type,
            headers: new Headers({
              "Authorization": "Basic " + btoa(wooId + ':' + wooPass),
              "Content-Type": "application/json"
            }),
            body: JSON.stringify(customer)
        });
        return await response.json();
    }
    catch (e) {
        return false;
    }
    finally {
        serviceStore._inProgress = false;
    }
}

export const createQuote = async(quote)  => {
    const serviceStore = useServiceStore();
    try {
        serviceStore._inProgress = true;
        const response = await fetch('https://www.deckibois.fr/ws_quote_2024.php', {
            method: 'POST',
            headers: new Headers({
              "Content-Type": "application/json"
            }),
            body: JSON.stringify(quote)
        });
        return await response.json();
    }
    catch (e) {
        return false;
    }
    finally {
        serviceStore._inProgress = false;
    }
}