<script>
export default {
  name: 'App',
}
</script>

<template>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */      
}

.page-content {
  width: 95%;
  margin: auto;
  max-width: 1500px;
}

h1 {
  font-size: 2em;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 25px;
}

.page-title .page-subtitle {
  font-size: 1em;
  margin: 0;
  padding: 0;
  color: #858585;
}

.labelRequired {
  font-size: 1.2em;
}

.labelRequired::after {
  content: "*";
  color: red;
}

input {
  text-align: center;
}

.mdc-button {
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 0!important;
  padding: 30px 100px!important;
  font-size: 1em!important;
  font-weight: bold!important;
  color: white!important;
  box-shadow: 0 0 0 0!important;
}

.mdc-button.disabled {
  background-color: #858585!important;
  border: 2px solid #858585!important;
  pointer-events: none;
}

.mdc-radio__outer-circle, .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #858585 !important;
}
		
.mdc-radio__inner-circle, .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #efab23 !important;
}

.mdc-button--primary {
  border: 2px solid #efab23!important;
  transition: 0.3s;
}

.mdc-button--primary:hover {
  background-color: #ffffff!important;
  color: #efab23!important;
  border: 2px solid #efab23!important;
  transition: 0.3s;
}

.nav {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.mdc-checkbox {
  padding-right: 0!important;
}

.mdc-checkbox--selected .mdc-checkbox__background {
  background-color: #efab23 !important;
  border: 2px solid #efab23 !important;
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 1.5em;
  }

  .labelRequired {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  .mdc-button {
    padding: 20px 50px!important;
    font-size: 0.8em!important;
  }
}

@media screen and (max-width: 768px) {
  .nav {

  }

  .nav button {
    font-size: 0.8em!important;
    width: 150px;
  }
}
</style>
