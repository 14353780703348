<script setup>
import { reactive, inject/*, onMounted, watchEffect*/ } from 'vue'
import { useQuotationStore } from './../stores/quotation'
import { useNavStore } from './../stores/nav'
import Loading from './../components/Loading.vue'
import { getProducts } from './../services/woo'

const quotationStore = useQuotationStore();

const navStore = useNavStore();
navStore.$pause();

const { emitCustomEvent } = inject('cancelBomb')
const { formatPrice } = inject('formatPrice')
const { filterDatas } = inject('filterDatas')
const { config } = inject('config')

const state = reactive({
  service : false,
  loading : {
    message : 'Chargement en cours, merci de patienter',
    status : true,
  },
  datas : [],
  access : [],
  validateBtn : true,
});

getProducts(
  config.services.accessoires.tagId, 
  config.wooId, 
  config.wooPass  
).then((res) => {
  if (res.data && res.data.status != 200) {
    state.loading = {
      message : res.message,
      status : false
    };
  } else {
    state.service = true;
    state.datas = filterDatas(res, quotationStore._essenceGamme, false, quotationStore._type);
    if (state.datas.length == 0) {
      quotationStore.$setAccessoires([]);
      navStore.$next();
    } else if (state.access.length == 0) {
      state.datas.forEach((data) => {
        if (data.favorite) {
          state.access[data.id] = data.name;
          validate(data.id, data.name, data.price, data.images.src, data.description);
        }
      });
    }
  }
  navStore.$resume();
  navStore.$restart(true);
});

const validate = (accessoireId, accessoireName, accessoirePrice, accessoireImg, accessoireDescription) => {
  if (accessoireId in quotationStore._accessoires) {
    quotationStore.$removeAccessoires(accessoireId);
  } else {
    quotationStore.$setAccessoires(accessoireId, accessoireName, accessoirePrice, accessoireImg, accessoireDescription);
  }
}
</script>

<template>
  <div class="page-content">
    <div class="page-title">
      <h1>Accessoires</h1>
      <div class="page-subtitle">
        <p>Nous vous conseillons les accessoires pré-sélectionnés</p>
      </div>
    </div>
    <div v-if="state.service == false">
      <Loading v-bind="{'msg': state.loading.message, 'status': state.loading.status}" />
    </div>
    <div @click="emitCustomEvent(this)" v-else>
      <div class="grid">
        <Slide v-for="data in state.datas" :key="data">
          <div class="grid__item" @click="validate(data.id, data.name, data.price, data.images.src, data.description)" >
            <div class="grid_img" v-if="data.images != undefined">
                <img :src="data.images.src" :class="{ 'grid__item_active': data.id in quotationStore._accessoires }" />
            </div>
            <div class="grid_img" v-else-if="data.image_already == true">
                <img src="./../assets/already.png" />
            </div>
            <div class="grid_img" v-else>
              No Pic
            </div>
            <div class="grid_price" v-if="data.price">
              {{ formatPrice(data.price) }} €
              <span v-if="data.unite == 'm2'">/m2</span>
              <span v-else-if="data.unite == 'ml'">/ml</span>
            </div>
            <div class="grid_title">
              {{ data.name }}
            </div>
            <div class="badge_container" v-if="data.favorite" >
                <div class="badge">Le choix Deckibois</div>
            </div>
          </div>
        </Slide>
      </div>
  
    <div class="nav">
      <button class="mdc-button mdc-button--raised mdc-button--primary" @click="navStore.$prev">Précédent</button>
      <button class="mdc-button mdc-button--raised mdc-button--primary" @click="navStore.$next">Suivant</button>
    </div>
    <!-- <div class="recap">
      <h2>Votre sélection</h2>
        <strong>Surface</strong> : {{ quotationStore._surface }}m2 - <strong>Type</strong> : {{ quotationStore._type }} {{ state.getProduct }}<br />
        <strong>Essence</strong> : {{ quotationStore._essenceName }} - <strong>Gamme</strong> : {{ quotationStore._essenceGamme }}<br />
        <span v-if="quotationStore._structureId > 0"><strong>Structure</strong>: {{ quotationStore._structureName }} - <strong>Gamme</strong> : {{ quotationStore._structureGamme }}<br /></span>
        <span v-else-if="quotationStore._structureId == 0"><strong>J'ai déjà ma structure</strong><br /></span>
        <span v-if="quotationStore._fixationId > 0"><strong>Fixation</strong>: {{ quotationStore._fixationName }} - <strong>Gamme</strong> : {{ quotationStore._fixationGamme }}<br /></span>
        <span v-else-if="quotationStore._fixationId == 0"><strong>J'ai déjà mes fixations</strong><br /></span>
      </div> -->
    </div>

  </div>
</template>

<style scoped>
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  justify-content: start;
}

.carousel__slide {
  width: 14%!important;
  flex-direction: column;
  justify-content: flex-start;
  margin: 5px 10px;
}

.grid__item {
  padding: 0px;
  width: 100%;
}

.grid_title {
  padding: 10px 5px;
  text-align: center;
  font-size: 1em;
}

.grid_img img {
  width: 100%;
  border: #eeee 4px solid;
  background-size: cover;
  background-position: center;
}

.grid_img img.grid__item_active {
  background-color: #efab2321;
  border: #efab23 4px solid;
  width : 100%;
  height: 100%;
}

.grid_price {
  font-size: 0.8em;
  padding: 0px;
  background-color: #efab23;
  color: #ffff;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  width: 100px;
  margin: 10px auto 0px auto;
}

.grid_img.already {
  max-height: 200px;
}

.badge_container {
    overflow: hidden;
    top: 0px;
    left: 0px;
    width: 118px;
    height: 115px;
    position: absolute;
    padding: 0;
}

.badge {
  position: absolute;
  top: 36px;
  background-color: #efab23;
  transform: rotate(-45deg);
  width: 155px;
  left: -35px;
  padding: 4px;
  font-size: 0.8em;
  color: #ffff;
  font-weight: bold;
}
.badge:before {
    left: 0;
}

.badge:after {
    right: 5px;
}

.validateBtn {
    width: 250px;
    height: 50px;
    margin: 50px 0 0 0;
}

@media (max-width: 1200px) {
  .carousel__slide {
    width: 30%!important;
  }
}

@media (max-width: 1024px) {
  .carousel__slide {
    width: 21%!important;
  }

  .grid_title {
    height: auto !important;
    padding: 40 20px !important;
  }
}

@media (max-width: 768px) {
  .carousel__slide {
    width: 42%!important;
  }
}
</style>