import { createWebHistory, createRouter } from "vue-router";
import General from "./../views/General.vue";
import Logout from "./../views/Logout.vue";

const routes = [
  {
    path: "/",
    name: "Dev",
    component: General,
  },
  {
    path: "/robot",
    name: "Robot",
    component: General,
  },
  {
    path: "/robot-test",
    name: "RobotTest",
    component: General,
  },
  {
    path: "/devis-gratuit/",
    name: "devis-gratuit",
    component: General,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;