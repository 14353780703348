<script setup>
import { ref, inject, reactive, onMounted, watchEffect } from 'vue'
import { useQuotationStore } from './../stores/quotation'
import { useNavStore } from './../stores/nav'

const navStore = useNavStore();
const quotationStore = useQuotationStore();

const surface = ref(quotationStore._surface);
const surfaceDefined = ref(false);
const type = ref(null);

if (surface.value > 0)
{
  surfaceDefined.value = true;
}

const state = reactive ({
  interMoins : null,
  interPlus : null
})

/*const changeValue = (amount) => {
  console.log(`Change amount by ${amount}`) // eslint-disable-line
}*/

const { emitCustomEvent } = inject('cancelBomb')

const validate = (type) => {
  quotationStore.$setSurface(surface.value, type);
  navStore.$next();
}

onMounted(() => {
  watchEffect(async() => {
    if (surface.value != undefined && parseInt(surface.value) > 0) {
      surfaceDefined.value = true;
    } else if (isNaN(parseInt(surface.value))) {
      surface.value = 0;
      surfaceDefined.value = false;
    }
  })
})

</script>

<template>
  <div class="step1" @click="emitCustomEvent(this)">
    <div class="page-title">
      <h1>Demande de devis gratuit</h1>
      <div class="page-subtitle">
        <p>Obtenez votre tarif personnalisé en quelques secondes et sans engagement.</p>
      </div>
    </div>

    <div class="surface">
      <div>
        <label for="surface" class="labelSurface labelRequired">Saisir votre surface</label> {{ state.moins }}
      </div>
      <ui-form-field class="surface-form">
        <ui-textfield name="surface" type="number" placeholder="0" v-model.lazy="surface" v-on:keyup="emitCustomEvent(this)" />
      </ui-form-field>
      <div class="surface-helper-line">
        <p>Calcul : largeur * longueur</p><br>
        <p>Anticipez les chutes en majorant votre surface de 8%</p>
      </div>
    </div>

    <div class="type" >
      <div>
        <label class="labelType labelRequired">Sélectionnez votre type de sol</label>
      </div>
      <div class="mdc-image-list">
        <div class="mdc-images">
          <a @click="type = 'sol terre'">
            <div class="mdc-image-bloc" :class="{ 'selected': type === 'sol terre' }">
              <img class="mdc-image" alt="" src="./../assets/terre.jpg" />
              <span>Sol meuble<br/>&nbsp;</span>
            </div>
          </a>
        </div>
        <div class="mdc-images">
          <a @click="type = 'sol dalle'">
            <div class="mdc-image-bloc" :class="{ 'selected': type === 'sol dalle' }">
              <img class="mdc-image" alt="" src="./../assets/dalle.jpg" />
              <span>Sol béton ou similaire</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div>
      <button @click="validate(type)" class="mdc-button mdc-button--raised mdc-button--primary" :class="{ 'disabled': type === null || !surfaceDefined }">Suivant</button>
    </div>
  </div>
</template>

<style scoped>
label {
  display: block;
  font-weight: 900;
  padding-bottom: 10px;
}
.labelType {
 margin-top: auto; 
}
  @media (max-width: 1024px) {
    .labelSurface {
      padding-bottom: 0;
      position: relative;
      top: 20px;
    }
    
    .labelType {
      margin-top: 20px !important;
    }
  }
.step1 {
  width: 100%;
  height: auto;
  min-height: 600px;
  text-align: center;
}

.step1 > div {
  padding: 20px;
}
  @media (max-width: 1024px) {
    .step1 > div {
      padding: 0 !important;
    }
  }

.hide {
  display: none;
  opacity: 0;
}

.show {
  animation: slideaway 500ms;
  display: block;
}

@keyframes slideaway {
  from { opacity: 0; }
  to { opacity: 1; }
}

.surface-helper-line {
  font-size: 0.8em;
  color: #858585;
  margin-top: 25px;
  line-height: 0;
}

.surface-form .mdc-text-field {
  margin: 0!important;
  width: 120px;
}

.surface-form .mdc-text-field::after {
  content: "m²";
}

.mdc-image-list {
  display: flex;
  justify-content: space-around;
  width: 500px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.mdc-images {
  width: 220px;
  height: 220px;
}

.mdc-images img {
  border: 5px solid #f5f5f5;
}

.mdc-images .selected img {
  border: 5px solid #efab23;
}

.mdc-image-bloc {
  display: grid;
}
.mdc-images a {
  text-decoration: none;
  color: #444444;
  cursor: pointer;
}
.mdc-images a:hover {
  color: #000000; 
}
.mdc-image-bloc span {
  margin-top:10px;
}
.mdc-image {
  width: 100%;
}

button {
  font-size: 2em;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

.mdc-text-field__input {
  background-color: white !important;
  vertical-align: top !important;
  font-size: 1.2em !important;
  height: 50px !important;
  margin: 0 !important;
}

@media (min-width: 1024px) {
  .mdc-text-field__input {
    margin-top: -15px !important;
  }
}

@media (max-width: 495px) {
  .mdc-image-list {
    width: 100%;
    height: 560px!important;
  }
}
</style>
