import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { config } from './config/config'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faSpinner } from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret, faSpinner)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import router from './router'
import App from './App.vue'

import { Carousel, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

import BalmUI from 'balm-ui'
//import BalmUIPlus from 'balm-ui/dist/balm-ui-plus'
import 'balm-ui/dist/balm-ui.css';

const app = createApp(App);
/*app.directive("theme", {
  mounted : function (el, binding) {
      console.log('mounted');
    if (binding.value === 'primary') {
      el.style.color = 'red'
    } else if (binding.value === 'secondary') {
      el.style.color = 'green'
    } else if (binding.value === 'tertiary') {
      el.style.color = 'blue'
    } else {
      el.style.color = 'black'
    }
  }
});*/
/*app.directive('longclick', {
    mounted: function (el, binding) {
        let pressTimer = null
        let start = (e) => {
            if (e.type === 'click' && e.button !== 0) {
                return;
            }
            
            if (pressTimer === null) {
                pressTimer = setTimeout(() => {
                    console.log('go');
                    handler()
                }, 50)
            }
        }
        let cancel = () => {
            if (pressTimer !== null) {
                clearTimeout(pressTimer)
                pressTimer = null
            }
        }
        const handler = (e) => {
            binding.value(e)
        }
        el.addEventListener("mousedown", start);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchstart", start);
    
        el.addEventListener("mouseup", cancel);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchend", cancel);
        el.addEventListener("touchcancel", cancel);
    },
});*/

app.component('Font-awesome-icon', FontAwesomeIcon);
app.component('Carousel', Carousel);
app.component('Slide', Slide);
app.component('Navigation', Navigation);

app.use(router);
app.use(createPinia());
app.use(BalmUI, {
  $theme: {
    primary: '#f9a825',
    secondary: '#ffffff',
    /*primary: '#f9a825',
    secondary: '#fdd835',
    background: '#fffde7',
    surface: '#f9a825',
    error: '#f9a825',
    onPrimary: '#f9a825',
    onSecondary: '#f9a825',
    onSurface: '#f9a825',
    onError: '#f9a825',*/
  }
});

app.provide('config', {
  config
});
//app.use(BalmUIPlus);
app.mount('#app');