export const config = {
    //prod : {
        wooId: 'ck_44599ccfe47289c7c104897911a5f13939d8ce38',
        wooPass : 'cs_65551a1e081aa1d50003ef9c47a26189f967f58e',
        wooUrl : 'www.deckibois.fr',
    //},
    dev : {
        wooId: 'ck_63869db0759b8ce87c8517de579e05b3a878ca2e',
        wooPass: 'cs_26bf2e4257d64032ff650c3df4ba0fc51239bbdf',
        wooUrl : 'devis.deckibois.fr',
    },
    services : {
        essences : {
            tagId : 56 // tag article woo
        },
        structures : {
            tagId : 86
        },
        fixations : {
            tagId : 81
        },
        accessoires : {
            tagId: 66,
            terre : {
                tagId : 61
            },
            dalle : {
                tagId : 62
            }
        }
    }
}