<script setup>
import { defineProps } from 'vue'
import { useNavStore } from './../stores/nav'
const navStore = useNavStore();

navStore.restartBomb = true;
const props = defineProps({
  msg : String,
  status : Boolean
})
</script>

<template>
  <div class="loadingContainer">
    <div>{{ props.msg }}</div>
    <div v-if="props.status"><Font-awesome-icon icon="fa-solid fa-spinner" class="load" size="2x" /></div>
  </div>
</template>

<style scoped>
.loadingContainer {
  display: grid;
  margin-top: 100px;
}

.loadingContainer div {
  padding: 10px;
}

.load {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
