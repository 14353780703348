import { defineStore } from 'pinia';

export const useNavStore = defineStore('navStore', () => {
  const step = 1;
  const maxStep = 1;
  const restartBomb = false;
  const pauseBomb = false;
  const resumeBomb = false;
  
  function $restart(bombVal) {
    this.restartBomb = bombVal;
  }
  
  function $setStep(step) {
    if (step <= this.maxStep || step <= 2)
    {
      this.step = step;
      this.maxStep = step;
      this.restartBomb = true;
    }
  }
  
  function $next() {
    this.step++;
    this.maxStep++;
  }

  function $prev() {
    this.step--;
  }
  
  function $reset() {
    this.step = 1;
    this.maxStep = 1;
    this.restartBomb = false;
  }
  
  function $pause() {
    this.resumeBomb = false;
    this.pauseBomb = true;
  }
  
  function $resume() {
    this.pauseBomb = false;
    this.resumeBomb = true;
  }
  
  return { step, maxStep, restartBomb, pauseBomb, resumeBomb, $restart, $setStep, $next, $prev, $reset, $pause, $resume };
})