<script setup>
import router from './../router'
import Loading from './../components/Loading.vue'
import { useNavStore } from './../stores/nav'
import { useQuotationStore } from './../stores/quotation'

const quotationStore = useQuotationStore();
const navStore = useNavStore();
quotationStore.$reset();
navStore.$reset();

setTimeout(() => {
  router.replace({ path: '/' });
}, 1000);
/*
async function getUser() {

  const res = await fetch('https://quotable.io/authors')
  const {results} = await res.json()
  const randomNum = Math.floor(Math.random() * results.length-1) 
  const randomItem = results[randomNum]
  authorBio.value = randomItem.bio
}*/

</script>

<template>
  <Loading v-bind="{msg: 'Déconnexion en cours...', status : true}" />
</template>

<style scoped>
.logout {
  font-weight: 900;
}
</style>
