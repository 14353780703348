<script setup>
import { reactive, onMounted, watchEffect } from 'vue'
import { useNavStore } from './../stores/nav'
import { useServiceStore } from './../stores/service'
// import router from './../router'

const navStore = useNavStore();
const serviceStore = useServiceStore();

const state = reactive({
  activeStep: '1',
  step: [true],
  completedSteps : [false],
  hover: false,
  mobile: (screen.width < 900) ? true : false
});

const goToStep = (step) =>
{
  if (serviceStore._inProgress === false)
  {
    state.activeStep = step;
    state.step.fill(false);
    state.step[state.activeStep - 1] = true;
    navStore.$setStep(step);
  }
}

// const logout = () => 
// {
//   router.replace({ path: '/logout' });
// }

onMounted(() => {
  watchEffect(async () => {
    goToStep(navStore.step);
  })
});
</script>

<template>
  <div class="steps">
    <div class="logo" >
      <a href="https://www.deckibois.fr"><img src="./../assets/logo.png" alt="Logo" /></a>
    </div>
    <div class="step" :class="{ 'active': state.step[0] }" @click="goToStep(1)" v-if="state.mobile == false">Surface</div>
    <div class="step" :class="{ 'active': state.step[0] }" @click="goToStep(1)" v-else>1</div>
    <div class="step" :class="{ 'active': state.step[1] }" @click="goToStep(2)" v-if="state.mobile == false">Essence de bois</div>
    <div class="step" :class="{ 'active': state.step[1] }" @click="goToStep(2)" v-else>2</div>
    <div class="step" :class="{ 'active': state.step[2] }" @click="goToStep(3)" v-if="state.mobile == false">Supports & Fixations</div>
    <div class="step" :class="{ 'active': state.step[2] }" @click="goToStep(3)" v-else>3</div>
    <div class="step" :class="{ 'active': state.step[3] }" @click="goToStep(4)" v-if="state.mobile == false">Accessoires</div>
    <div class="step" :class="{ 'active': state.step[3] }" @click="goToStep(4)" v-else>4</div>
    <div class="step" :class="{ 'active': state.step[4] }" @click="goToStep(5)" v-if="state.mobile == false">Infos personnelles</div>
    <div class="step" :class="{ 'active': state.step[4] }" @click="goToStep(5)" v-else>5</div>
    <div class="step" :class="{ 'active': state.step[5] }" @click="goToStep(6)" v-if="state.mobile == false">Devis</div>
    <div class="step" :class="{ 'active': state.step[5] }" @click="goToStep(6)" v-else>6</div>
  </div>
</template>

<style scoped>
/*@media (min-width: 900px) {*/
  .steps {
    display: flex !important;
  }
/*}

@media (max-width: 899px) {
  .step {
    display: none !important;
  }
}*/
.steps {
  display: block;
  width: 100%;
}

.step_logout {
  border: 0;
  flex-grow: 1;
  display: none;
}

.step_logout > img {
  width: 48px;
  height: 48px;
  margin-top: 10px;
  max-height: inherit;
}

.step_logout_active {
  display: block;
}

.step, .stepWelcome {
  flex-grow: 2;
  border: 1px solid #eeeeee;
  padding: 20px;
  display: block;
}

.steps div:hover {
  cursor: pointer;
  background-color: #fafafa;
}

.active {
  font-weight: 900;
  background-color: #efab23 !important;
  color: white;
}

.hideit {
  display: none;
}

.logo {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 100px;
  max-height: inherit;
}

@media (max-width: 899px) {
  .step {
    display: none;
  }
}
</style>
