import { defineStore } from 'pinia';

export const useQuotationStore = defineStore('quotationStore', () => {
  // Step1
  const _surface = 0;
  const _type = '';
  // Step2
  const _essenceId = 0;
  const _essenceName = '';
  const _essenceTags = [];
  const _essenceGamme = '';
  const _essencePrice = 0;
  const _essenceImg = '';
  const _essenceDescription = '';
  // Step3
  const _structureId = 0;
  const _structureName = '';
  const _structureTags = [];
  const _structureGamme = '';
  const _structurePrice = 0;
  const _structureImg = '';
  const _structureDescription = '';
  // Step4
  const _fixationId = 0;
  const _fixationName = '';
  const _fixationTags = [];
  const _fixationGamme = '';
  const _fixationPrice = 0;
  const _fixationImg = '';
  const _fixationDescription = '';
  // Step5
  const _accessoires = [];
  // Step6
  const _email = '';
  const _customer = [];
  const _poseur = '';
  const _delai = '';
  const _rappel = '';
  const _message = '';
  const _cgv = false;

  const _totalPrice = 0;
  
  function $setSurface(surface, type) {
    this._surface = surface;
    this._type = type;
    //console.log('Surface = ', surface, 'Type = ', type);
  }
  
  function $setEssence(essenceId, essenceName, essenceTags, essenceGamme, essencePrice, essenceImg, essenceDescription) {
    this._essenceId = essenceId;
    this._essenceName = essenceName;
    this._essenceTags = essenceTags;
    this._essenceGamme = essenceGamme;
    this._essencePrice = essencePrice;
    this._essenceImg = essenceImg;
    this._essenceDescription = essenceDescription;
   // console.log('essenceId = ', essenceId, 'essenceName = ', essenceName, 'essenceTags =', essenceTags, 'essenceGamme =',essenceGamme);
  }
  
  function $setStructure(structureId, structureName, structureTags, structureGamme, structurePrice, structureImg, structureDescription) {
    this._structureId = structureId;
    this._structureName = structureName;
    this._structureTags = structureTags;
    this._structureGamme = structureGamme;
    this._structurePrice = structurePrice;
    this._structureImg = structureImg;
    this._structureDescription = structureDescription;
  //    console.log('structureId = ', structureId, 'structureName = ', structureName, 'structureTags =', structureTags, 'structureGamme =',structureGamme);
  }
  
  function $setFixation(fixationId, fixationName, fixationTags, fixationGamme, fixationPrice, fixationImg, fixationDescription) {
    this._fixationId = fixationId;
    this._fixationName = fixationName;
    this._fixationTags = fixationTags;
    this._fixationGamme = fixationGamme;
    this._fixationPrice = fixationPrice;
    this._fixationImg = fixationImg;
    this._fixationDescription = fixationDescription;
    //  console.log('fixationGamme = ', fixationGamme, 'fixationName = ', fixationName, 'fixationTags =', fixationTags, 'fixationGamme =',fixationGamme);
  }
  
  function $setAccessoires(accessoiresId, accessoiresName, accessoiresPrice, accessoiresImg, accessoiresDescription) {
    this._accessoires[accessoiresId] = {id: accessoiresId, name: accessoiresName, price: accessoiresPrice, img: accessoiresImg, description: accessoiresDescription};
    // console.log('accessoires = ',accessoires);
  }

  function $removeAccessoires(accessoiresId) {
    delete this._accessoires[accessoiresId];
    // console.log('accessoires = ',accessoires);
  }
  
  function $setEmail(email) {
    this._email = email;
    //console.log('email =',email);
  }
  
  function $setCustomer(customer) {
    this._customer = customer;
    //console.log('customer = ', this._customer);
  }

  function $setPoseur(poseur) {
    this._poseur = poseur;
    //console.log('poseur =',poseur);
  }

  function $setDelai(delai) {
    this._delai = delai;
    //console.log('délai =',délai);
  }

  function $setRappel(rappel) {
    this._rappel = rappel;
    //console.log('rappel =',rappel);
  }

  function $setMessage(message) {
    this._message = message;
    //console.log('message =',message);
  }

  function $totalPrice() {
    let totalPrice = 0;
    // sum essence, structure, fixation
    totalPrice += parseFloat(this._essencePrice*this._surface) + parseFloat(this._structurePrice) + parseFloat(this._fixationPrice);
    // sum accessoires
    for (let key in this._accessoires) {
      totalPrice += parseFloat(this._accessoires[key].price);
    }
    this._totalPrice = totalPrice;
  }
  
  function $reset() {
    this._surface = 0;
    this._type = '';
    
    this._essenceId = 0;
    this._essenceName = '';
    this._essenceTags = [];
    this._essenceGamme = '';
    this._essencePrice = 0;
    this._essenceImg = '';
    this._essenceDescription = '';
    
    this._structureId = 0;
    this._structureName = '';
    this._structureTags = [];
    this._structureGamme = '';
    this._structurePrice = 0;
    this._structureImg = '';
    this._structureDescription = '';
    
    this._fixationId = 0;
    this._fixationName = '';
    this._fixationTags = [];
    this._fixationGamme = '';
    this._fixationPrice = 0;
    this._fixationImg = '';
    this._fixationDescription = '';
    
    this._accessoires = [];
    this._email = '';
    this._customer = [];
    this._poseur = '';
    this._delai = '';
    this._rappel = '';
    this._message = '';
    this._cgv = false;
  }

  return { 
    _surface, 
    _type, 
    _essenceId, 
    _essenceName, 
    _essenceTags,
    _essenceGamme,
    _essencePrice,
    _essenceImg,
    _essenceDescription,
    _structureId,
    _structureName,
    _structureTags,
    _structureGamme,
    _structurePrice,
    _structureImg,
    _structureDescription,
    _fixationId,
    _fixationName,
    _fixationTags,
    _fixationGamme,
    _fixationPrice,
    _fixationImg,
    _fixationDescription,
    _accessoires,
    _email,
    _customer,
    _poseur,
    _delai,
    _rappel,
    _message,
    _totalPrice,
    _cgv,
    $setSurface, 
    $setEssence,
    $setStructure,
    $setFixation,
    $setAccessoires,
    $removeAccessoires,
    $setEmail,
    $setCustomer,
    $setPoseur,
    $setDelai,
    $setRappel,
    $setMessage,
    $totalPrice,
    $reset
  };
})